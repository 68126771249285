<template>
  <Popup
    class="edit-user"
    :modal-id="modal_id"
    :on-close="onModalClose"
    custom-class="gray-header"
    :toggle-status="toggleStatus"
  >
    <template v-slot:header>{{ labels.changePassword }}</template>

    <template v-slot:body>
      <div class="edit-user__body">
        <div class="row">
          <div class="col-md-6 col-12">
            <FormulateInput
              type="password"
              name="password"
              :label="labels.new_password"
              validation="required"
              error-behavior="blur"
              v-model="password"
            />
          </div>
          <div class="col-md-6 col-12">
            <FormulateInput
              type="password"
              name="confirmPassword"
              :label="labels.confirm_password"
              validation="required"
              error-behavior="blur"
              v-model="confirmPassword"
            />
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="edit-user__footer">
        <FormulateInput
          type="submit"
          class="btn-purple"
          :label="labels.edit_user"
          form="new-entity"
          @click="localResetPW"
        />

        <Button
          :label="labels.close"
          variant="btn-outline-purple"
          font-weight="600"
          :action="onModalClose"
        />
      </div>
    </template>
  </Popup>
</template>

<script>
import Popup from "@/components/Popups/Popup";
import Button from "@/components/Buttons/Button";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Popup,
    Button,
  },
  data() {
    return {
      modal_id: "2",
      toggleStatus: true,
      password: "",
      confirmPassword: "",
    };
  },
  methods: {
    ...mapActions("user", ["resetPW"]),
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("helper", {
      getCountries: "getCountries",
    }),
    onModalClose() {
      this.$router.push({
        name: "users",
        params: { kind: this.$route.params.kind },
      });
      //   if (this.routeBack && Object.keys(this.routeBack).length) {
      //     this.$router.push(this.routeBack);
      //   } else {
      //     this.$router.push({ name: "users", params: { kind: this.userKind } });
      //   }

      let element = document.querySelector(".modal-backdrop");
      if (element) {
        element.remove();
        document.querySelector("body").classList.remove("modal-open");
      }
    },
    localResetPW() {
      this.resetPW({
        id: this.$route.params.userId,
        payload: {
          password: this.password,
          confirmPassword: this.confirmPassword,
        },
      })
        .then((res) => {
          if (res.success) {
            this.password = "";
            this.confirmPassword = "";
            this.addNotification({
              variant: "success",
              msg: ["user_updated"],
              labels: this.labels,
            });
          } else {
            this.password = "";
            this.confirmPassword = "";
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
      this.$router.push({
        name: "users",
        params: { kind: this.$route.params.kind },
      });
    },
  },
  computed: {
    ...mapGetters("helper", {
      hasPermissions: "hasPermissions",
      labels: "labels",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.edit-user {
  background-color: rgba($color: $black, $alpha: 0.5);
  &__header-title {
    font-family: "Oswald", sans-serif;
  }

  &__body {
    margin-top: -15px;

    div[class*="col-"] {
      margin-top: 15px;
    }

    .phones-container {
      margin-top: 15px;

      label {
        font-family: "Oswald", sans-serif;
        margin-bottom: 10px;
        display: block;
        line-height: 1.5;
        font-size: 0.9em;
        font-weight: 600;
      }

      .phone {
        display: flex;
        margin-top: 15px;

        ::v-deep {
          .formulate-input {
            flex: 1 0 auto;
            margin-bottom: 0px;
          }

          button {
            margin-left: 15px;
            width: 110px;
          }
        }
      }
    }

    ::v-deep .formulate-input {
      .formulate-input-wrapper {
        label {
          font-family: "Oswald", sans-serif;
          margin-bottom: 10px;
        }

        .formulate-input-element {
          max-width: 100%;
        }

        .formulate-input-group {
          display: flex;
          .formulate-input-group-item {
            margin-right: 10px;
            margin-bottom: 0;
          }
          label {
            margin-bottom: 0;
            font-family: inherit;
            font-weight: inherit;
          }
        }
      }

      &.horizontal-label-input {
        .formulate-input-wrapper {
          display: flex;
          align-items: center;
          gap: 3rem;

          .formulate-input-element {
            flex: 1 1 auto;
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    ::v-deep .formulate-input {
      margin-bottom: 0px !important;

      .formulate-input-element {
        button {
          margin-bottom: 0px;
          margin-right: 15px;
        }
      }
    }
  }
}
</style>
